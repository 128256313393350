/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Badge, Dashboard, DashboardOutlined,
    Flag,
    HomeWork,
    Mail,
    PermIdentity,
    PhoneAndroid,
    School, ShortText, Link, Title, QrCode2, Percent
} from "@mui/icons-material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropCustom from "../../Modulo_formularios/Componentes/IngresoImagenCropCustom";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {MOSTRAREN} from "../../Constantes";

const Formulario_Descuento = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={QrCode2} nombre={'Codigo Descuento'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Percent} nombre={'Valor del descuento'} dato={'valor'} type={'number'}  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Descuento