// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// insecol.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyD8cpkhLNWwgHoXPCg5ClUGlQKw-m7rTk4",
    authDomain: "pagina-3d4ae.firebaseapp.com",
    projectId: "pagina-3d4ae",
    storageBucket: "pagina-3d4ae.appspot.com",
    messagingSenderId: "1061294863747",
    appId: "1:1061294863747:web:7172de996f61b4d12a32b6",
    measurementId: "G-BWWP3WH2YT"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)