/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Badge, Dashboard, DashboardOutlined,
    Flag,
    HomeWork,
    Mail,
    PermIdentity,
    PhoneAndroid,
    School, Texture, Title
} from "@mui/icons-material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropCustom from "../../Modulo_formularios/Componentes/IngresoImagenCropCustom";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {MOSTRAREN} from "../../Constantes";
import IngresoTextoLista from "../../Modulo_formularios/Componentes/IngresoTextoLista";

const Formulario_Colecciones = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagen dato={'img'}  {...props} />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Dashboard} nombre={'Nombre de Coleccion'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion Parrafo 1'} dato={'parrafoUno'}  {...props}
                                  lineas={7}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTextoLista nombre={'Lista de Piedras'} dato={'lista'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion Parrafo 2'} dato={'parrafoDos'}  {...props}
                                  lineas={7}
                    />
                </Grid>




            </Grid>
        </form>

    )

}
export default Formulario_Colecciones