/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Badge, Dashboard, DashboardOutlined,
    Flag,
    HomeWork,
    Mail,
    PermIdentity,
    PhoneAndroid,
    School, ShortText, Link, Title, Money, AccountBalance, Receipt, PriceCheck, Schedule, GppGood, Policy
} from "@mui/icons-material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropCustom from "../../Modulo_formularios/Componentes/IngresoImagenCropCustom";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOS, ESTADOSPAGO, MOSTRAREN} from "../../Constantes";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import {consultarPagoReferencia} from "../../Modulo_Pasarela_Pagos/Funciones/consultarPagoReferencia";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_negro.png'
import {useEffect, useState} from "react";
import {PDFDownloadLink} from "@react-pdf/renderer";
import Pdf_Recibo_Dashboard from "../../Modulo_Pasarela_Pagos/Pdfs/Pdf_Recibo_Dashboard";
import logo_png from "../../Recursos/logo_negro.png";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";

const Formulario_Cobro = ({...props}) => {
    const [datos, setDatos] = useState({})
    const [cobro, setCobro] = useState({})
    const {abrirCargador, cerrarCargador, Cargador} = useLoaders({logo: logo})


    const consultarPago = () => {

        let ref = props.props.getValues('referencia')

        if (!ref) {
            alert('No hay referencia de pago')
            return
        }

        abrirCargador('Consultando Pago')
        consultarPagoReferencia(ref).then((dox) => {
            if (dox.res) {
                if (dox.res && dox.data.status === 'error') {
                    alert(dox.data.description)
                    cerrarCargador()
                    return;
                }

                let obj = {
                    estado: dox.data.x_response,
                    valor: dox.data.x_amount_ok
                }
                setDatos(obj)

            } else {
                alert('no hay datos para esta referencia de pago, consulta tu dashboard de ePayco')
            }
            cerrarCargador()
        })
    }


    useEffect(() => {
        let cobro_ = props.props.getValues()
        setCobro({
            ...cobro_, productos: [{
                cantidad: 1,
                nombre: 'Pay # ' + cobro_.consecutivo + ' ' + cobro_.descripcion,
                valorFinal: cobro_.valor
            }]
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Cargador/>

                {props.esNuevo &&
                    <>

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>

                        </Grid>

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-end'}}>
                            {cobro.nombre &&
                                <PDFDownloadLink document={<Pdf_Recibo_Dashboard pedido={cobro} logo={logo_png}/>}
                                                 fileName={"Cobro " + cobro.nombre}
                                                 style={{textDecoration: "none"}}>
                                    <Button
                                        startIcon={<Receipt/>}
                                        size={'small'}
                                        sx={{pt: 1, pb: 0.8}}
                                        fullWidth
                                    >
                                        Recibo
                                    </Button>
                                </PDFDownloadLink>
                            }
                        </Grid>

                    </>
                }

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={AccountCircle} nombre={'Nombre Cliente'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={ShortText} nombre={'Descripcion'} dato={'descripcion'} lineas={4} {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={Money} nombre={'Valor en USD'} dato={'valor'}  {...props}
                    />
                </Grid>

                {props.esNuevo &&
                    <>
                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{color: '#00000090'}}>Datos de Pago</Typography>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoSelect Icono={AccountBalance} nombre={'Pago'} dato={'estadoPago'}
                                           opciones={ESTADOSPAGO} {...props}

                            />
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoDinero Icono={PriceCheck} nombre={'Cargo'} dato={'pagado'}
                                           opciones={ESTADOSPAGO} {...props} editable={false}

                            />
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <IngresoTexto Icono={Schedule} nombre={'Fecha de Pago'} dato={'fechaPago'}
                                          editable={false}
                                          {...props}

                            />
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <IngresoTexto Icono={AccountBalance} nombre={'Banco'} dato={'nombreBanco'}
                                          editable={false}
                                          {...props}
                            />
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Receipt} nombre={'Ref Epayco'} dato={'referenciaEpayco'}
                                          {...props} editable={false}

                            />
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={GppGood} nombre={'Num Aprobacion'} dato={'numeroAprobacion'}
                                          {...props} editable={false}

                            />
                        </Grid>


                        <Grid item container lg={6} sm={6} xs={8} sx={{justifyContent: 'flex-start', marginTop: 0.5}}>
                            <Button
                                onClick={() => consultarPago()}
                                startIcon={<Policy/>} color={'primary'} size={'small'} sx={{px: 2, pt: 1, pb: 0.8}}>Verificar
                                Pago</Button>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={4} sx={{justifyContent: 'flex-end'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                    <Typography>{datos && datos.estado}</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                                    <Typography>{datos && formatoMoneda(datos.valor)}</Typography>
                                </Grid>


                            </Grid>
                        </Grid>

                    </>
                }


            </Grid>
        </form>

    )

}
export default Formulario_Cobro