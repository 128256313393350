/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import {
    CampaignOutlined,
    DashboardOutlined,
    Layers,
    LocalOffer,
    Newspaper,
    RequestQuote,
    ShoppingCart
} from "@mui/icons-material";
import logo from '../Recursos/logo_oscuro.svg'
import logo_redondo from '../Recursos/logo_oscuro.svg'
import logo_blanco from '../Recursos/logo_blanco.svg'
import {useContext, useEffect} from "react";
import {usuarioContext} from "../App";
import {funSalir} from "../Servicios/Auth/funSalir";
import Seccion_Colecciones from "./Secciones/Seccion_Colecciones";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";
import {actualizarDoc} from "../Servicios/BD/actualizarDoc";
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";
import Seccion_Configuraciones from "./Secciones/Seccion_Configuraciones";
import Seccion_Anuncios from "./Secciones/Seccion_Anuncios";
import Seccion_Noticias from "./Secciones/Seccion_Noticias";
import Seccion_Pedidos from "./Secciones/Seccion_Pedidos";
import Seccion_Cobro from "./Secciones/Seccion_Cobro";
import Seccion_Descuentos from "./Secciones/Seccion_Descuentos";

const Dashboard = () => {
    const cData = useContext(usuarioContext)
    const {Notificador} = useEscucharNotificacion()
    const {token} = useGetToken()

    const irPerfil = () => {
        // alert('ir Perfil')
    }


    useEffect(() => {
        if (cData.usuario) {
            let usuario = cData.usuario
            if (token && usuario.token !== token) {
                actualizarDoc('usuarios', usuario.id, {token: token}).then((dox) => {
                    if (dox.res) {
                        console.log('cambio token')
                    }
                })
            }

        }

    }, [token]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Notificador/>

            <DashboardBase secciones={secciones}
                           credenciales={['Pedidos', 'Colecciones', 'Catalogo', 'Cobros', 'Descuentos', 'Anuncios', 'Noticias', 'Configuraciones']}>
                <MenuLateral logo={logo} clickPerfil={irPerfil} logoRedondo={logo_redondo} logoBlanco={logo_blanco}
                             usuario={cData.usuario} salir={funSalir}/>
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [
    {
        nombre: 'Pedidos',
        Componente: <Seccion_Pedidos/>,
        icono: ShoppingCart,
        categoria: 'propia',
        camino: '/',
    },
    {
        nombre: 'Colecciones',
        Componente: <Seccion_Colecciones/>,
        icono: DashboardOutlined,
        categoria: 'Tienda',
        camino: '/Colecciones',
    },
    {
        nombre: 'Catalogo',
        Componente: <Seccion_Catalogo/>,
        icono: Layers,
        categoria: 'Tienda',
        camino: '/Catalogo',
    },
    {
        nombre: 'Cobros',
        Componente: <Seccion_Cobro/>,
        icono: RequestQuote,
        categoria: 'Tienda',
        camino: '/Cobros',
    },
    {
        nombre: 'Descuentos',
        Componente: <Seccion_Descuentos/>,
        icono: LocalOffer,
        categoria: 'Tienda',
        camino: '/Descuentos',
    },
    {
        nombre: 'Anuncios',
        Componente: <Seccion_Anuncios/>,
        icono: CampaignOutlined,
        categoria: 'Pagina',
        camino: '/Anuncios',
    },
    {
        nombre: 'Noticias',
        Componente: <Seccion_Noticias/>,
        icono: Newspaper,
        categoria: 'Pagina',
        camino: '/Noticias',
    },
    {
        nombre: 'Configuraciones',
        Componente: <Seccion_Configuraciones/>,
        icono: Layers,
        categoria: 'Pagina',
        camino: '/Configuraciones',
    },
]