/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Dashboard, Layers} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {MOSTRAREN} from "../../Constantes";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoVideosLista from "../../Modulo_formularios/Componentes/IngresoVideosLista";
import IngresoTextoLista from "../../Modulo_formularios/Componentes/IngresoTextoLista";
import IngresoSwich from "../../Modulo_formularios/Componentes/IngresoSwich";

const Formulario_Producto = ({...props}) => {
    const [nomColeccines, setNomColeciones] = useState([])


    useEffect(() => {
        obtenerCol('colecciones').then((dox) => {
            if (dox.res) {
                let data = dox.data
                let noms = data.map((item) => item.nombre)
                setNomColeciones(noms)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop dato={'img'} alto={3} ancho={2} {...props} />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop dato={'imgAtras'} alto={3} ancho={2} {...props} />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSwich Icono={Layers} nombre={'Producto en Stock'} dato={'stock'}  {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Layers} nombre={'Nombre del producto'} dato={'nombre'}  {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion Parrafo 1'} dato={'parrafoUno'}  {...props}
                                  lineas={7}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTextoLista nombre={'Lista de Caracteristicas'} dato={'lista'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion Parrafo 2'} dato={'parrafoDos'}  {...props}
                                  lineas={7}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTextoLista nombre={'Lista de Caracteristicas'} dato={'listaDos'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion Parrafo 3'} dato={'parrafoTres'}  {...props}
                                  lineas={7}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <IngresoSelect dato={'coleccion'} nombre={'Coleccion'} Icono={Dashboard}
                                       opciones={nomColeccines} {...props}
                                       requerido={'La coleccion es requerida'}/>
                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoDinero dato={'valor'} nombre={'Valor en USD'} opciones={MOSTRAREN} {...props}
                                   requerido={'Donde se mostrarra es requerido'}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Imagenes adicionales</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCropLista dato={'imgs'} alto={3} ancho={2} {...props}  />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Videos</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoVideosLista dato={'videos'} alto={3} ancho={2} {...props}  />
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_Producto