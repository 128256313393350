/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment,
    Badge, Dashboard, DashboardOutlined,
    Flag,
    HomeWork,
    Mail,
    PermIdentity,
    PhoneAndroid,
    School
} from "@mui/icons-material";
import IngresoImagen from "../../Modulo_formularios/Componentes/IngresoImagen";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropCustom from "../../Modulo_formularios/Componentes/IngresoImagenCropCustom";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {MOSTRAREN} from "../../Constantes";
import {useEffect, useState} from "react";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";

const Formulario_Configuracion_Home = ({...props}) => {
    const [nomColecciones, setNomColecciones] = useState([])

    useEffect(() => {
        obtenerCol('colecciones').then((dox) => {
            if (dox.res) {
                let data = dox.data
                let noms = data.map((it) => it.nombre)
                setNomColecciones(noms)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelect Icono={Dashboard} dato={'coleccion'} nombre={'Coleccion que se muestra en Home'}
                                   opciones={nomColecciones} {...props}
                                   requerido={'La coleccion es obligatoria'}/>
                </Grid>

                <Grid item container lg={7} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>

                </Grid>

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoTexto Icono={Dashboard} dato={'textoTallas'} nombre={'Texto de tallas'}
                                  {...props} lineas={8}
                                   requerido={'El texto de tallas es obligatoria'}/>
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Configuracion_Home