/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Link, ShortText, Title} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";

const Formulario_Noticias = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCrop Icono={Title} dato={'img'}  {...props} ancho={1} alto={1}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Title} nombre={'Titulo del Noticia'} dato={'nombre'}  {...props}
                                  requerido={'El Titulo de la noticia es obligatorio'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={ShortText} nombre={'Medio que lo publico'} dato={'subtitulo'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={ShortText} nombre={'Descripcion de la noticia'} dato={'descripcion'}
                                  lineas={7} requerido={"La descripcion de la noticia es requerida"}
                                  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Link} nombre={'Link de Noticia completa'} dato={'link'}  {...props}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Noticias