/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import {theme} from "../../Tema";
import {Edit, TaskAlt} from "@mui/icons-material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Configuracion_Home from "../Formularios/Formulario_Configuracion_Home";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'

const Seccion_Configuraciones = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [recargar, setRecargar] = useState(false)
    const {props, setEntidad, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})


    const guardarCambios = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando configuracion')
            let obj = entidad
            obj.id = 'home'
            guardarDoc('configuraciones', obj).then((dox) => {
                if (dox.res) {
                    alert('Cambios guardados')

                }
                cerrarCargador()
            })
        })
    }

    const botones = [
        <Button
            onClick={() => guardarCambios()}
            startIcon={<TaskAlt/>}>
            Guardar
        </Button>
    ]


    useEffect(() => {


        obtenerDoc('configuraciones', 'home').then((dox) => {
            if (dox.res && dox.data) {
                setEntidad(dox.data)
            }
        })


    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Configuraciones '} textoComplementario={''}
                                      textoResaltado={`Configures generales`} botones={botones}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_Configuracion_Home props={props}/>
            </Grid>


        </Grid>
    )

}
export default Seccion_Configuraciones

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    },
    {
        nombre: 'Se muestra en',
        direccion: 'right',
        propiedad: 'mostrando',

    }


]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    },
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    },

]



